<template>
  <div class="my_box">
    <!-- 顶部分类 -->
    <div class="my_header">
      <ul class="level1">
        <li
          v-for="(item, index) in level1Arr" :key="item.id" class="level1_item"
          :class="{ active: level1Index === index }" @click="changeActiveLevel1(index)">{{ item.title }}</li>
      </ul>
      <ul class="level2">
        <li
          v-for="(item, index) in level1List" :key="item.key" class="level2_item"
          :class="{ active: level2Index === index }" @click="changeActiveLevel2(index, item.name)">{{ item.name }}</li>
      </ul>
    </div>
    <!-- 内容 -->
    <div class="my_content">
      <vueScroll v-show="matList.length" ref="vs" @handle-scroll="handleScroll">
        <div>
          <!-- 当前版本，id是会重复的 -->
          <ModelItem
            v-for="(item, index) in lastMatList" :key="index" :tabid="my.level1Index === downIndex ? 5 : 6" from="my"
            class="model_item" :item="modelItem(item)" />
        </div>
      </vueScroll>
      <div v-show="!matList.length" class="no_data">
        <div class="img" />
        <p v-show="level1Index === downIndex" class="info">没有下载记录，快去下载吧</p>
        <p v-show="level1Index === collectIndex" class="info">没有收藏记录，快去收藏吧</p>
      </div>
    </div>
    <!-- 分类 -->
    <div class="bottom_pagination">
      <Pagination
        :total="matTotalCount" :defaultActive="my[Page]"
        @selectIndex="selectIndex" />
    </div>
  </div>
</template>

<script>
import ModelItem from '@/components/ModelItem/index.vue'
import Pagination from '@/components/Pagination/index.vue'

import { strictExtend } from '@shared/utils/base/index.js'
import { modelStatus, isCollect, Cat1, Page, MatId, pageSize, Scroll, scrollRateFast, scrollRateAm } from '@/common/const.js'
import { report } from '@/report/index.js'

const params = { [Cat1]: 0, [Page]: 1, size: pageSize }
const pageDefault = 1
const scrollDefault = 0

export default {
  name: 'My',

  components: { ModelItem, Pagination },

  data () {
    return {
      downIndex: 0, // 下载的固定下标
      collectIndex: 1, // 收藏的固定下标
      MatId,
      Page,
      level1Arr: [{ id: 0, title: '我的下载' }, { id: 1, title: '我的收藏' }],
      matListParams: strictExtend(params)
    }
  },

  computed: {
    level1List () {
      return this.$store.state.operational.categoryArr
    },

    my () {
      return this.$store.state.my
    },

    level2Index () {
      return this.my.level2Index
    },

    level1Index () {
      return this.my.level1Index
    },

    downedList () {
      return this.$store.state.downedList
    },

    downingList () {
      return this.$store.state.downingList
    },

    collectIdList () {
      return this.$store.state.collectIdList
    },

    matList () {
      return this.$store.state.matList
    },

    matTotalCount () {
      return this.$store.state.matTotalCount
    },

    lastMatList () {
      return this.matList.map(item => {
        const id = item.mat_id + ''

        let type = this.downedList.includes(id) ? modelStatus.downed : modelStatus.down
        type = this.downingList.includes(id) ? modelStatus.downing : type

        const collectActive = this.collectIdList.includes(item.mat_id)
        return {
          ...item,
          type,
          [isCollect]: collectActive
        }
      })
    }
  },

  created () {
    this.reportView()
    this.initState()
    this.getMatList()
  },

  methods: {
    reportView () {
      const tabid = this.my.level1Index === this.downIndex ? 5 : 6
      report('listpage', { actiontype: 1, tabid, showtype: '', clickid: 5, goodsid: '', goodstype: '', free: '', classid: '', ext: '' })
    },

    initState () {
      this.matListParams[Cat1] = this.level1List[this.level2Index].id
      this.matListParams[Page] = this.my[Page]
      this.$nextTick(() => {
        this.$refs.vs.scrollTo({ y: this.my[Scroll] }, scrollRateAm)
      })
    },

    getMatList () {
      this.$store.dispatch('getMyMat', this.matListParams)
    },

    modelItem (item) {
      // 跳转参数from在详情页左右切换时需要更改store对应的缓存数据
      return {
        id: item.mat_id,
        type: item.type,
        [isCollect]: item[isCollect],
        title: item.mat_name,
        img: item.mat_cover_img,
        url: `/detail?from=my&${MatId}=${item.mat_id}`
      }
    },

    changeActiveLevel2 (index) {
      this.$store.commit('changeMy', { level2Index: index, [Page]: pageDefault, [Scroll]: scrollDefault })

      this.matListParams[Cat1] = this.level1List[this.level2Index].id
      this.matListParams[Page] = pageDefault

      this.getMatList()
    },

    changeActiveLevel1 (index) {
      if (this.my.level1Index === index) { return }

      this.$store.commit('changeMy', { level1Index: index, level2Index: 0, [Page]: pageDefault, [Scroll]: scrollDefault })

      this.reportView()

      this.matListParams[Cat1] = this.level1List[this.level2Index].id
      this.matListParams[Page] = pageDefault

      this.getMatList()
    },

    selectIndex (index) {
      this.$store.commit('changeMy', { [Page]: index })
      this.$refs.vs.scrollTo({ y: 0 }, scrollRateFast)

      this.matListParams[Page] = index

      this.getMatList()
    },

    handleScroll (v, h, e) { // 记录滚动的位置, handle-scroll-complete不生效，到时候可以优化
      this.$store.commit('changeMy', { [Scroll]: v.scrollTop })
    }
  }
}
</script>

<style lang="scss" scoped>
.my_box { display: flex; flex-direction: column; height: 100%;
  .my_header{ font-size: 12px; color: rgba(68, 68, 68, 0.7); border-bottom: 1px solid $border_color; }
  .level1 { display: flex; height: 40px; padding: 0 22px; background-color: #fff; }
  .level1_item { position: relative; display: flex; align-items: center; height: 100%; cursor: pointer;
    &:hover { color: $primary_select; }
    &.active { font-weight: bold; color: $primary_color;
      &::after { position: absolute; bottom: 0; left: 50%; width: 90%; height: 2px; content: ''; background: $primary_color; transform: translateX(-50%); }
    }
    &+.level1_item { margin-left: 38px  ; }
  }

  .level2 { display: flex; height: 40px; padding: 0 22px; }
  .level2_item { position: relative; display: flex; align-items: center; height: 100%; cursor: pointer;
    &:hover { color: $primary_select; }
    &.active { color: $primary_color; }
    &+.level2_item { margin-left: 38px  ; }
  }

  .my_content {  display: flex; flex: 1; flex-wrap: wrap; height: calc(100% - 117px); padding: 4px 4px; padding-bottom: 60px; overflow: hidden;
    .model_item { margin: 8px 10px; }
  }
  .no_data { display: flex; flex: 1; flex-direction: column; align-items: center; justify-content: center;
    .img { width: 303px; height: 215px; background: url(~@/assets/images/bg_no_data.png) center center no-repeat; background-size: cover; }
    .info { margin-top: 34px; font-size: 20px; font-weight: 400; color: #7F93BE; }
  }

  .bottom_pagination {  display: flex; align-items: center; justify-content: center; height: 36px; }
}
</style>
