// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/bg_no_data.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".my_box[data-v-5907bd93]{display:flex;flex-direction:column;height:100%}.my_box .my_header[data-v-5907bd93]{font-size:12px;color:rgba(68,68,68,.7);border-bottom:1px solid #dedede}.my_box .level1[data-v-5907bd93]{display:flex;height:40px;padding:0 22px;background-color:#fff}.my_box .level1_item[data-v-5907bd93]{position:relative;display:flex;align-items:center;height:100%;cursor:pointer}.my_box .level1_item[data-v-5907bd93]:hover{color:#3c7dc2}.my_box .level1_item.active[data-v-5907bd93]{font-weight:700;color:#4a96e8}.my_box .level1_item.active[data-v-5907bd93]:after{position:absolute;bottom:0;left:50%;width:90%;height:2px;content:\"\";background:#4a96e8;transform:translateX(-50%)}.my_box .level1_item+.level1_item[data-v-5907bd93]{margin-left:38px}.my_box .level2[data-v-5907bd93]{display:flex;height:40px;padding:0 22px}.my_box .level2_item[data-v-5907bd93]{position:relative;display:flex;align-items:center;height:100%;cursor:pointer}.my_box .level2_item[data-v-5907bd93]:hover{color:#3c7dc2}.my_box .level2_item.active[data-v-5907bd93]{color:#4a96e8}.my_box .level2_item+.level2_item[data-v-5907bd93]{margin-left:38px}.my_box .my_content[data-v-5907bd93]{display:flex;flex:1;flex-wrap:wrap;height:calc(100% - 117px);padding:4px 4px;padding-bottom:60px;overflow:hidden}.my_box .my_content .model_item[data-v-5907bd93]{margin:8px 10px}.my_box .no_data[data-v-5907bd93]{display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center}.my_box .no_data .img[data-v-5907bd93]{width:303px;height:215px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:cover}.my_box .no_data .info[data-v-5907bd93]{margin-top:34px;font-size:20px;font-weight:400;color:#7f93be}.my_box .bottom_pagination[data-v-5907bd93]{display:flex;align-items:center;justify-content:center;height:36px}", ""]);
// Exports
module.exports = exports;
